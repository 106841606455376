import React, { useState,useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { useSnackbar } from 'notistack';
import { Box, Fab,Button, Paper, Typography,TextField } from '@mui/material';
import Layout from './Layout';
import mqtt from 'mqtt';
// import LogViewer from './LogViewer';
// import "./index.css"

  // var mqttClient;
  var THISAPPID
  const videoTopic = 'video_frame';

  // Función para generar un ID único alfanumérico
const generateUniqueId = (length) => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return result;
};

// Generar el clientId único al cargar la página
const uniqueClientId = `cloudDevice_${generateUniqueId(6)}`;


const Dashboard = () => {
    const { qrcode } = useParams();

    const { enqueueSnackbar } = useSnackbar();
  const [url, setUrl] = useState('https://iotayudo.com');
  const [messages, setMessages] = useState("");
  const [connected, setConnected] = useState(false);
  const [blinkState, setBlinkState] = useState(false);
  const [blinkStateMq, setBlinkStateMq] = useState(false);
  let timer = null; // Declarar el temporizador fuera del componente de React
  
  const [client, setClient] = useState(null);
  const [currentResponse, setCurrentResponse] = useState(null); // Estado para controlar el componente actual
  const [currentComponent, setCurrentComponent] = useState('Layout'); // Estado para controlar el componente actual
  const [selectedItem, setSelectedItem] = useState(null); // menú lateal izquierdo
  
  const [ctdsensor, setCtdsensor] = useState(null); // menú lateal izquierdo
  const [dataPeer, setDataPeer] = useState("minuts"); // menú lateal izquierdo
  

const [currentDevice, setCurrentDevice] = useState(null);
  const [subscription, setSubscription] = useState(null);
  
  
  const [imageSrc, setImageSrc] = useState('');
  const [fade, setFade] = useState(false);
  
  const [controlState, setControlState] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const navigate = useNavigate();


  const [, updateState] = React.useState();
const forceUpdate = React.useCallback(() => updateState({}), []);

useEffect(() => {
    // Función para conectar al servidor MQTT y suscribirse al nuevo dispositivo
    setCurrentResponse(null);    
    setSelectedItem(null);
    setCtdsensor(null)

    const connectAndSubscribe = () => {
      // alert(uniqueClientId);
      // const options = {
        // clientId: uniqueClientId,
        // username: "mtx5833",
        // password: "Alterxp5",
        // clean: true
        const options = {
          clientId: uniqueClientId,
          username: process.env.REACT_APP_MQTT_USERNAME,
          password: process.env.REACT_APP_MQTT_PASSWORD,
          clean: true
      };

      // const mqttClient = mqtt.connect('wss://2807ccce5f924766a34afeab1eb54217.s2.eu.hivemq.cloud:8884/mqtt', options);
      const mqttClient = mqtt.connect(process.env.REACT_APP_MQTT_URL, options);

      // alert(process.env.REACT_APP_MQTT_USERNAME);

      mqttClient.on('message', (topic, message,packet) => {
        // console.log(' topic:', topic.toString());
        const isRetained = packet && packet.retain;
        // console.log(' packet:', packet);
        if (isRetained) {
          // console.log('Mensaje retenido recibido, descartado:', message.toString());
          return;
        }

        if (topic === 'RESPONSE/' + currentDevice) {
            console.log('RESPONSE/' + currentDevice);
            
            setBlinkStateMq(true);
            clearTimeout(timer); // Reiniciar el temporizador cada vez que llega un mensaje
            timer = setTimeout(() => {
              setBlinkStateMq(false); // Si no llega ningún mensaje en 10 segundos, el cliente está inactivo
              // alert('El cliente está inactivo');
            }, 30000); // 10000 ms = 10 segundos
          
          try {
            const jsonData = JSON.parse(message);        
            console.log(String(message));

            if ("NAMEDEVICE" in jsonData && "Perifs" in jsonData) {
              setCurrentResponse(jsonData);
              if(jsonData.ctdSensors){
                setCtdsensor(jsonData.ctdSensors)
              }

              enqueueSnackbar(currentDevice+" online", { variant: "info" });
            } else if ("peripheric" in jsonData && "state" in jsonData) {
              // Si el mensaje es del segundo tipo, actualizamos currentResponse según las reglas proporcionadas
              const periphericIdx = jsonData.peripheric;
              const newState = jsonData.state;
        
              // Actualizamos currentResponse según las reglas
              setCurrentResponse(prevResponse => {
                if (prevResponse && prevResponse.Perifs && Array.isArray(prevResponse.Perifs)) {
                  const updatedPerifs = prevResponse.Perifs.map(perif => {
                    if (perif.idx === periphericIdx) {
                        enqueueSnackbar("Periferico "+perif.idx+" to "+ newState, { variant: "success" });

                      return {...perif, state: newState}; // Actualizamos el estado según el nuevo estado recibido
                    }
                    return perif;
                  });
                  return {...prevResponse, Perifs: updatedPerifs};
                }
                return prevResponse;
              });

              
              const pushNot="{\"device\": \""+currentDevice+"\",\"destiny\": \"1375fff5-f689-45df-9c5d-628fd56c52c0\",\"message\": \"CAMBIO ENTRADA "+periphericIdx+" STATE "+(newState?"ENCENDIDO":"APAGADO")+"\"}";
              console.log(pushNot);
              mqttClient.publish('notification_channel',pushNot,1, error => {    
                if (error) {
                  console.log('Publish error: ', error);
                }
              }); 
              
            } else if ("ControlState" in jsonData) {
                setControlState(jsonData.ControlState);
                
                enqueueSnackbar("ControlState "+jsonData.ControlState, { variant: "success" });
            } else if ("ONLINE" in jsonData) {            
              mqttClient.publish("REQUEST/"+currentDevice, "showmeyougot");
              mqttClient.publish("REQUEST/"+currentDevice, "{getControlState}");    
              enqueueSnackbar("DEVICE ONLINE ", { variant: "info" });

            } else if ("readings" in jsonData) {            
              // mqttClient.publish("REQUEST/"+currentDevice, "showmeyougot");
              // mqttClient.publish("REQUEST/"+currentDevice, "{getControlState}");    
              setCurrentResponse(jsonData);
              enqueueSnackbar("readings ", { variant: "info" });
            } else {
              

                // {ONLINE}
              console.error('Mensaje no reconocido:', message);
            }
          } catch (error) {
            console.error('Error al parsear JSON:', error);
            setCurrentResponse(null); // Establecer data como null si hay un error al parsear JSON
          }
        }
        
        

      });

     
      mqttClient.on('reconnect', () => {
        console.log('Reconnecting to MQTT broker...');
        if (client) {
          client.reconnect();
        }
      });

      mqttClient.on('connect', () => {
        console.log('Connected to MQTT broker');
        mqttClient.subscribe('RESPONSE/' + currentDevice,{ rh: 0 });
        setBlinkStateMq(false);
        setSubscription('RESPONSE/' + currentDevice); // Actualiza el estado de la subscripción

        setSelectedItem("/perif");

        setClient(mqttClient);
        setConnected(true);
        enqueueSnackbar("Connected to server", { variant: "success" });

      });


    };

    // Verifica si hay una subscripción previa y finalízala antes de crear una nueva
    if (client && subscription !== 'RESPONSE/' + currentDevice) {
      client.unsubscribe(subscription); // Finaliza la subscripción previa
      setSubscription(null); // Limpia el estado de la subscripción
      setClient(null); // Limpia el cliente MQTT para evitar la creación de una nueva subscripción con el cliente anterior
    }else{
       // return;
    }

    // Conecta y suscríbete al nuevo dispositivo
    if (currentDevice) {
        enqueueSnackbar("currentDevice "+currentDevice, { variant: "info" });

        
      connectAndSubscribe();

      
    }

    // Limpia la subscripción cuando el componente se desmonta
    return () => {
      if (client) {
        client.end();
      }
    };
  }, [currentDevice]); // Ejecuta el efecto cuando currentDevice cambia


  


  const handlePublish = (topic, message) => {
    // alert(message)
    if (client) {
      // alert(topic)
      client.publish(topic, message, (error) => {
        if (error) {
          console.log('Publish error:', error);
        }else{
          console.log('Publish:', message);
        }
      });
    }
  };


  const handleDisconnect = () => {
    if (client) {
      client.end();
      setConnected(false);
    }
  };

  useEffect(() => {
    
    const THISAPPID = localStorage.getItem('THISAPPID');
    if (!THISAPPID) {
      // Generar un ID aleatorio si no hay uno en el almacenamiento local
      const newAppID = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
      localStorage.setItem('THISAPPID', newAppID);
    }
    
    enqueueSnackbar("Selec any device", { variant: "info" });

  }, []); // El segundo argumento es un array vacío, lo que significa que esta función se ejecutará solo una vez al montar el componente

  
  useEffect(() => {
    const intervalId = setInterval(() => {
      // Cambiar el estado de parpadeo
        if(connected){
            setBlinkState(prevState => !prevState);
                forceUpdate();
        }else{
            setBlinkState(false);
        }
       }, 1000);
    return () => clearInterval(intervalId);
  }, [connected]); 
  
  
  
  
  useEffect(() => {
    console.log("**>>El estado de selectedItem ha cambiado:", selectedItem);
  
    eventCheckselectedItem();

  }, [selectedItem]); // Esta dependencia indica que el efecto se ejecutará cada vez que currentComponent cambie


  const eventCheckselectedItem = () => {
    if(selectedItem==="/perif"){
      console.log("**>>handlePublish:showmeyougot");
      handlePublish("REQUEST/"+currentDevice, "showmeyougot");
      handlePublish("REQUEST/"+currentDevice, "{getControlState}");
      setCurrentResponse(null);

    }else if(selectedItem && selectedItem.match(/^\/graph\d+$/)){
      console.log("**>>handlePublish:graph");
      const sensorIndex = parseInt(selectedItem.replace('/graph', ''), 10);

      const jsonData = {
        path: "\graph",
        sensor:sensorIndex,
        dataspeer:dataPeer
      };
      const jsonString = JSON.stringify(jsonData);
      handlePublish("REQUEST/" + currentDevice, jsonString); // Llamar a handlePublish con los datos apropiados
      setCurrentResponse(null);

    }
    
  };
  useEffect(() => {
    eventCheckselectedItem();
  }, [dataPeer]);


  useEffect(() => {
    return () => {
      if (client) {
        client.end();
      }
    };
  }, [client]);


  
  const handleSignOut = () => {
    localStorage.removeItem('token');
    navigate('/');
  };

  const renderComponent = () => {
    switch (currentComponent) {
      case 'SignIn':
        return (          
                <div>
                
          {/* <LogViewer />  */}
          


                {/* <QRCode value={url} /> */}
                {/* <Typography variant="h4">{"iotayudo.com"}</Typography> */}
                <TextField
                  // fieldErrors={fieldErrors}
                  // disabled={processing}
                  name="username"
                  label="Username"
                  // value={signInRequest.username}
                  // onChange={updateLoginRequestValue}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                />
                <TextField
                  // fieldErrors={fieldErrors}
                  // disabled={processing}
                  type="password"
                  name="password"
                  label="Password"
                  // value={signInRequest.password}
                  // onChange={updateLoginRequestValue}
                  // onKeyDown={submitOnEnter}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                />
              </div>
        );
        case 'Layout':
          return (
            // <Layout/>
             <> 
             <Layout handlePublish={handlePublish} 

                  selectedItem={selectedItem}
                  setSelectedItem={setSelectedItem} 
                  dataPeer={dataPeer}
                  setDataPeer={setDataPeer}
                  eventCheckselectedItem={eventCheckselectedItem}
                  ctdsensor={ctdsensor}

                  currentResponse={currentResponse} 
                  setCurrentResponse={setCurrentResponse}
                  connected={connected}
                  blinkState={blinkState}
                  blinkStateMq={blinkStateMq}
                  currentDevice={currentDevice}
                  setCurrentDevice={setCurrentDevice}
                  imageSrc={imageSrc}
                  fade={fade}
                  controlState={controlState}
                  setControlState={setControlState}
                  waiting={waiting}
                  setWaiting={setWaiting}
                  
                  qrcode={qrcode}                  
                  />
            </>
           
          );
        case 'Perif':
          return (
            // <Layout/>
            <></>

          );
          
      // Agrega más casos según sea necesario para otros componentes
      default:
        return null;
    }
  };



  return (
    
    <Box
      display="flex"
      // height="100vh"
      margin="auto"
      padding={2}
      justifyContent="center"
      flexDirection="column"
      width="100%"
      // maxWidth={(theme) => theme.breakpoints.values.sm}
    >
      

      {renderComponent()}

      <ul>
      {messages}
      </ul><ul  style={{ textAlign: 'right' }}>
          
      </ul>
    </Box>
  );
};

export default Dashboard;
