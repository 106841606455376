import React, { useState } from 'react';
import './toggleButton.css'; // archivo de estilos

function ToggleButton(props) {
  const { state, onChange } = props;

  return (
    <div className="toggle-container" onClick={onChange} style={{ cursor: 'pointer' }}>
      <div className={`dialog-button ${state ? "active" : ""}`}>
        <div className={`dialog-button-circle ${state ? "active" : ""}`}></div>
      </div>
      <span>{state ? "ON" : "OFF"}</span>
    </div>
  );
}


export default ToggleButton;
