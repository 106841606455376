import React, { useState, Fragment,useEffect } from 'react';
import './App.css';
import './toggleButton.css';
import ToggleButton from './ToggleButton';
import './styles.css'; // Asegúrate de importar el archivo CSS
import mqtt from 'mqtt';


import {
  Grid,
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  Box,
} from '@mui/material';


const getClientId = () => {
  let storedClientId = localStorage.getItem('mqttClientId');

  if (!storedClientId) {
    // Generar un clientId único (puedes usar cualquier método, aquí uso un UUID simple)
    storedClientId = `Client_${Math.random().toString(36).substr(2, 9)}_${Date.now()}`;
    localStorage.setItem('mqttClientId', storedClientId);
    alert(storedClientId)
  }

  return storedClientId;
};

// Obtener el clientId desde localStorage o generarlo
const clientId = getClientId();

// Configurar las opciones MQTT con el clientId dinámico
const options = {
  clientId, // Usar el clientId generado o existente
  username: "GBIC_USER",
  password: "Alterxp33",
  clean: true, // Limpiar la sesión al desconectarse
};
  

  var client;
 
 

  const initialList = [
    {
      id: 1,
      name: "Morelia01",
      espid: "esp32-d8132aee5ed8",
      complete: false,
    },
    {
      id: 2,
      name: "Morelia02",
      espid: "esp32-e465b8adaa94",
      complete: false,
    },
    {
      id: 3,
      name: "Morelia03",
      espid: "esp32-e465b8adaa40",
      complete: false,
    },
    {
      id: 4,
      name: "Morelia04",
      espid: "esp32-e465b8ada844",
      complete: false,
    },
    {
      id: 5,
      name: "Morelia05",
      espid: "esp32-e465b8adab94",
      complete: false,
    },
    {
      id: 6,
      name: "Morelia06",
      espid: "esp32-e465b8adaba8",
      complete: false,
    },
    {
      id: 7,
      name: "Morelia07",
      espid: "esp32-08f9e08931cc",
      complete: false,
    },
    {
      id: 8,
      name: "Morelia08",
      espid: "esp32-e465b8ada608",
      complete: false,
    },
    {
      id: 9,
      name: "Morelia09",
      espid: "esp32-e465b8ada5d0",
      complete: false,
    },
    {
      id: 10,
      name: "Morelia10",
      espid: "esp32-d8132aee5e08",
      complete: false,
    },
  ];
  
const initCurrentID = [
  { DeviceID: '' , complete: false},
  { Server: 'sensores.gbic.mx' , complete: false},
  { IP: '' , complete: false},
  { Port: '8086' , complete: false},
  { Time: '10000' , complete: false},
  { Token: '9yVls0LERVkNgRmaAMio6J4SbZ7IRI7ZZyS77LXKqzjKzZj-qT3iirJ5YJK5c5d3OrD2EzthGI2WqWzzyziAEQ==' , complete: false},
  { Database: 'sensores' , complete: false},
  { Ubicación: 'Morelia01' , complete: false},
  { Zona: 'Centro' , complete: false},
  { Compilation: '0.0' , complete: false}
  
];

function DevicesBendita() {
  
  const [Toggled1,setToggled1]=useState(false);
  const [Toggled2,setToggled2]=useState(false);
  const [url, setUrl] = useState('https://iotayudo.com/download/sketch2.111.bin'); // Estado para la URL


  const [value, setValue] = React.useState('');
  const [list, setList] = React.useState(initialList);
  // const [list, setList] = useState(() => {
  //   const savedList = localStorage.getItem('list');
  //   alert(savedList)
  //   return savedList ? JSON.parse(savedList) : initialList;
  //   });

  useEffect(() => {
    localStorage.setItem('list', JSON.stringify(list));
    if(mqttconnected){
      list.forEach((device) => {
        mqclient.subscribe(`gbic/RESPONSE/${device["espid"]}`, { qos: 1 }, (error) => {
          if (error) {
            console.log(`Error al suscribirse a ${device["espid"]}:`, error);
          } else {
            console.log(`Suscrito a ${device["espid"]}`);
          }
        });
        // alert(`gbic/RESPONSE/${device["espid"]}`);
      });

    }

  }, [list]);

  // const [disabledList, setDisabledList] = React.useState(false);
  const [activityMap, setActivityMap] = React.useState({}); // Estado de actividad por `espid`

  const [currentID, setCurrentID] = React.useState(initCurrentID);
 
  const [mqttconnected,setMqttconnected]=useState(false);   
  const [currentDeviceID, setCurrentDeviceID] = useState("");
  const [mqclient, setMqClient] = useState(null);
  
  
  const handleToggle = (idx, currentState, setState) => {
    const newState = !currentState; // Cambia el estado
    setState(newState);

    const jsonData = {
      peripheric: idx, // Identificador del botón
      state: newState, // Nuevo estado
    };
    const jsonString = JSON.stringify(jsonData);

    // Publicar en el tópico MQTT correspondiente
    if (client && currentDeviceID) {
      client.publish(`gbic/REQUEST/${currentDeviceID}`, jsonString, (error) => {
        if (error) {
          console.error('Error publicando en MQTT:', error);
        } else {
          console.log(`Publicado: ${jsonString}`);
        }
      });
    } else {
      console.log('MQTT client o dispositivo no disponible');
    }
  };

  let eventHandled = false;
  
   const handleChangeList = (event, lstName) => {
    if (eventHandled) return; // Si ya se manejó un evento, ignorar el click
    eventHandled = true;
    setTimeout(() => {
      eventHandled = false;
    }, 1000); // Ajusta el tiempo según sea necesario

    const selectedValue = event.target.value;

    // Actualiza el mensaje y el estado actual
    setMesg2(`Current DEVICEID: ${selectedValue}`);
    setCurrentDeviceID(selectedValue);

    setCurrentID({
      ...currentID,
      DeviceID: '',
      IP: '',
      Server: '',
      token: '',
      Time: '',
      Token: '',
      Database: '',
      Ubicación: selectedValue,
      Zona: '',
      Compilation:''
    });
    
    if (!mqttconnected) {
      console.log('No conectado');
      alert('No conectado');
      return;
    }

    // Publica el comando en el cliente MQTT
    client.publish(
      `gbic/REQUEST/${selectedValue}`,
      JSON.stringify({ showmeyougot: 'true' }),
      1,
      (error) => {
        if (error) {
          console.log('Publish error: ', error);
        }
      }
    );
  };


  const handleChangeTx = event => {
    setValue(event.target.value);
  };  

  const handleChangeDevice = (event, arg2) => {
    if(event==="DeviceID"){
        setCurrentID({...currentID, DeviceID:arg2})
    // }else if(event==="Server"){
    //     setCurrentID({...currentID, Server:arg2})
    // }else if(event==="token"){
    //     setCurrentID({...currentID, token:arg2})
    // }else if(event==="Time"){
    //     setCurrentID({...currentID, Time:arg2})
    // }else if(event==="Database"){
    //     setCurrentID({...currentID, Database:arg2})
    }else if(event==="Ubicación"){
        setCurrentID({...currentID, Ubicación:arg2})
    }else if(event==="Zona"){
        setCurrentID({...currentID, Zona:arg2})
    }
    
    //alert(event+" " +arg2);
}

  const handleSubmiturl = (event) => {
    event.preventDefault(); // Evitar el comportamiento por defecto del formulario

    if (!client || !currentDeviceID) {
      console.log('MQTT client o dispositivo no disponible');
      return;
    }

    const jsonData = { updateFirmFrom: url };
    const jsonString = JSON.stringify(jsonData);
return;

    // Publicar en el tópico MQTT correspondiente
    client.publish(`gbic/REQUEST/${currentDeviceID}`, jsonString, (error) => {
      if (error) {
        console.error('Error publicando en MQTT:', error);
      } else {
        console.log(`Publicado: ${jsonString}`);
      }
    });
  };

  const handleAdd = event => {
    event.preventDefault();

  if (value) {
    const newItem = {
      id: list.length + 1, // Asignamos un id único
      name: value,
      espid: value, // Asignamos el espid igual al name
      complete: false,
    };

    // Verificamos si el espid ya existe en la lista
    const exists = list.some((item) => item.espid === newItem.espid);

    if (!exists) {
      // Agregamos el nuevo item si no existe
      setList((prevList) => [...prevList, newItem]);
    } else {
      alert(`El dispositivo con espid "${newItem.espid}" ya existe en la lista.`);
    }
  }

  setValue(''); // Limpiamos el campo de entrada
  };



  const handleSubmiteElements= (event, field) => {
    if(currentDeviceID== ""){
        alert("Elije un device ID")
        return;
      }
    if(!mqttconnected){
      alert("No connectado")
      return;
    }
    event.preventDefault();  // Prevenir el comportamiento predeterminado del formulario

    const jsonData = {
      elemento: field,   // Identificador del campo (por ejemplo, 'Server', 'Time', etc.)
      value: currentID[field],  // El nuevo valor del campo
    };
  
    const jsonString = JSON.stringify(jsonData);
  
    alert(jsonString);

    // Publicar en el tópico MQTT correspondiente
    if (client && currentID.DeviceID) {
      const topic = `gbic/REQUEST/${currentID.DeviceID}`;
      client.publish(topic, jsonString, (error) => {
        if (error) {
          console.error('Error al publicar en MQTT:', error);
        } else {
          console.log(`Publicado en el tópico: ${topic}`);
        }
      });
    }
  };

  
  useEffect(() => {
    const timer = setInterval(() => {
      //client.publish('CHANNEL/REQUEST/OMARHOUSE/ncyQqRSIYp',"{\"refreshmedata\": \"true\"}",1, error => {          
      //      if (error) {
      //   console.log('Publish error: ', error);
      // }
      //});

    }, 40000);
    return () => clearTimeout(timer);
  }, []);

  var note;

  function connectToMQTT() {
    //alert("no llega")
    if(currentDeviceID===""){
      // alert("Elije un device ID")
      // return;
    }    
    if(!mqttconnected){
          // alert(mqttconnected)

      client=mqtt.connect('wss://2807ccce5f924766a34afeab1eb54217.s2.eu.hivemq.cloud:8884/mqtt',options)
      client.on('connect',()=>{   
             
              list.forEach((device) => {
                client.subscribe(`gbic/RESPONSE/${device["espid"]}`, { qos: 1 }, (error) => {
                  if (error) {
                    console.log(`Error al suscribirse a ${device["espid"]}:`, error);
                  } else {
                    console.log(`Suscrito a ${device["espid"]}`);
                  }
                });
                // alert(`gbic/RESPONSE/${device["espid"]}`);
              });


              setMqClient(client);
              // setdisabledList(true);
              setMqttconnected(true)  
      })

      client.on('message', (topic, message) => {
        // alert(message)
        // Identificar el `espid` del tópico
        const espid = topic.split('/').pop();

        const data = JSON.parse(message);
        // Actualizar el estado de actividad
        setActivityMap((prev) => ({
          ...prev,
          [espid]: true, // Marca actividad para el dispositivo
        }));

        console.log(data.COMPILATION)


        const isAnswordShowme = 
        data.server && 
        data.token && 
        data.time && 
        data.db && 
        data.location && 
        data.zone;


        if (isAnswordShowme) {
          // Actualizar el estado de `currentID` con los valores recibidos
          setCurrentID((prev) => ({
            ...prev,
            DeviceID: espid,
            IP: data.IP || '',
            Server: data.server || '',
            token: data.token || '',
            Time: data.time || '',
            Token: data.token ? data.token.substring(0, 20)+"..." : '' || '', // Parece que 'Token' es lo mismo que 'token', pero verifica
            Database: data.db || '',
            Ubicación: data.location || '', // Asegúrate de que 'location' existe en el JSON
            Zona: data.zone || '',
            Compilation:data.COMPILATION
          }));

          if (data.Perifs && Array.isArray(data.Perifs)) {
            // Buscar los estados de los idx 4 y 5
            const perif3 = data.Perifs.find((perif) => perif.idx === 3);
            const perif4 = data.Perifs.find((perif) => perif.idx === 4);
        
            // Actualizar los estados de Toggled1 y Toggled2
            setToggled1(perif3 ? perif3.state : false); // Si no existe perif4, se usa false como valor predeterminado
            setToggled2(perif4 ? perif4.state : false); // Si no existe perif5, se usa false como valor predeterminado
          }

          
          const updatedList = prevList => 
            prevList.map(item => 
              item.espid === espid 
                ? { ...item, name: data.location } // Si encontramos el espid, actualizamos el name
                : item // Si no es el espid correspondiente, mantenemos el elemento
            );    
          // Actualizamos el estado de la lista
          setList(updatedList);    
          // Guardamos la lista actualizada en localStorage
          localStorage.setItem('list', JSON.stringify(updatedList));
        }
          
        

        // Restablecer actividad después de 3 segundos
        setTimeout(() => {
          setActivityMap((prev) => ({
            ...prev,
            [espid]: false,
          }));
        }, 20000);
      });
      
    }else{
      alert("Desconected");
      setdisabledList(false);
      setMqttconnected(false)
      //client.end();
      client.end(() => {
        setMqClient(null);
      });

      console.log(currentID);

      setCurrentID({...currentID, 
        
        DeviceID: "",
        IP: '',
        Server: '',
        token: '',
        Time: '',
        Token: '',
        Database: '',
        Ubicación: '',
        Zona: '',
        Compilation:''
      })
      
        console.log(currentID);
    }
    
  }

  

  const handleClearLocalStorage = () => {
    localStorage.removeItem('list');
    setList([]);  // Restablece la lista a los valores iniciales
  };
   
      
      
  // Sets default React state 
  const [mesg, setMesg] = useState(<Fragment><em>nothing heard</em></Fragment>);
  const [mesg2, setMesg2] = useState(<Fragment><em>nothing heard</em></Fragment>);
  
  const [connected, setConnected] = useState(false);
  const [disabledList, setdisabledList] = useState(false);

  
  const handleDownloadList = () => {
    const jsonData = JSON.stringify(list, null, 2); // Convertimos a JSON con formato
    const blob = new Blob([jsonData], { type: 'application/json' }); // Creamos el archivo
    const url = URL.createObjectURL(blob); // Generamos la URL
    const a = document.createElement('a'); // Creamos un enlace
    a.href = url;
    a.download = 'list.json'; // Nombre del archivo
    a.click(); // Disparamos la descarga
    URL.revokeObjectURL(url); // Liberamos la URL
  };

  const handleUploadList = (event) => {
    const file = event.target.files[0]; // Obtenemos el archivo
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const uploadedList = JSON.parse(e.target.result);
          if (Array.isArray(uploadedList)) {
            setList(uploadedList);
            localStorage.setItem('list', JSON.stringify(uploadedList)); // Guardamos en localStorage
            console.log(uploadedList)
          } else {
            alert('El archivo no tiene el formato adecuado.');
          }
        } catch (error) {
          alert('Error al leer el archivo: formato no válido.');
        }
      };
      reader.readAsText(file);
    }
  };

  
   return (
    <Box p={2}>
      <Grid container spacing={3}>
        {/* Grupo Izquierdo */}
        <Grid item xs={12} md={4}>
          <Box>
            <Typography variant="h6">Device Connection</Typography>
            <Button
              variant="contained"
              color={mqttconnected ? 'error' : 'primary'}
              onClick={connectToMQTT}
              fullWidth
            >
              {mqttconnected ? 'Disconnect' : 'Connect'}
            </Button>
          </Box>

          <Box mt={3}>
            <FormControl fullWidth > 
              {/* <InputLabel>DEVICES</InputLabel> */}
              {/* <Select
                labelId="list-label"
                value={currentDeviceID}
                onChange={(e) => handleChangeList(e, 'LeftList')}
                disabled={disabledList}
                
              >
                {list.map((data, index) => (
                  <MenuItem key={index + data.value} value={data.name}>
                    {data.name}
                  </MenuItem>
                ))}
              </Select> */}
              {/* <select className='select-tag' name="cars" disabled={disabledList} multiple aria-label="label for the select Left" onChange={(e) => {
                        handleChangeList(e,'LeftList');}}>
                        {list.map((data, index) => (

                            < option key={index + data.value} value={data.value} >{data.name}</option>
                        ))
                        }
                        </select> */}

                <div className="dropdown-container">
                      <label htmlFor="dropdown">Seleccionar un dispositivo:</label>
                      <select
                        className="select-tag"
                        name="devices"
                        disabled={disabledList}
                        multiple
                        aria-label="label for the select Left"
                        onChange={(e) => {
                          handleChangeList(e, 'LeftList');
                        }}
                        onClick={(e) => {
                          handleChangeList(e, 'LeftList');
                        }}
                         sx={{height:"600px"}}
                      >
                        {list!==null && list.map((data, index) => (
                            <option
                              key={data.espid}
                              value={data.espid}
                              style={{
                                // backgroundColor: activityMap[data.espid] ? 'lightgreen' : 'white', // Fondo
                                color: activityMap[data.espid] ? 'black' : 'lightgreen', // Cambia el color si hay actividad
                                fontWeight: activityMap[data.espid] ? 'bold' : 'normal', // Negrita si hay actividad
                              }}
                            >
                            {data.name}
                          </option>
                        ))}
                      </select>
                    </div>
              
            </FormControl>
          </Box>

          <Box mt={2}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleClearLocalStorage}
              fullWidth
            >
              Clear LocalStorage
            </Button>
          </Box>

          <Box mt={3}>
            <form onSubmit={handleAdd}>
              <TextField
                label="Add Item"
                value={value}
                onChange={handleChangeTx}
                fullWidth
              />
              <Button type="submit" variant="contained" color="primary" fullWidth>
                Add Item
              </Button>
            </form>
          </Box>

          
      <Box mt={2}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleDownloadList}
        >
          Download List
        </Button>
      </Box>

      <Box mt={2}>
        <Button variant="contained" component="label" fullWidth>
          Upload List
          <input
            type="file"
            hidden
            accept=".json"
            onChange={handleUploadList}
          />
        </Button>
      </Box>

        </Grid>

        {/* Grupo Central */}
        <Grid item xs={12} md={4}>
          <Typography variant="h6">Device Configuration</Typography>


          {['DeviceID', 'IP', 'Server', 'Time', 'Token', 'Database', 'Ubicación','Zona'].map((field) => (
            <Box mt={2} key={field}>
              <form onSubmit={(event) => handleSubmiteElements(event, field)}>
                <TextField
                  label={field}
                  value={currentID[field] || ''}
                  onChange={(event) => handleChangeDevice(field, event.target.value)}
                  fullWidth
                />
                <Button type="submit" variant="contained" color="primary" fullWidth>
                  Send
                </Button>
              </form>
            </Box>
          ))}

          {/* <Box mt={3}>
            <Typography>Mensaje:</Typography>
            <TextField value={currentID.mensaje || ''} disabled fullWidth />
          </Box>

          <Box mt={3}>
            <Typography>Timestamp:</Typography>
            <TextField value={currentID.timestamp || ''} disabled fullWidth />
          </Box> */}

          {/* <Box mt={3}>
            <form onSubmit={(event) => handleSubmiteDevice(event, 'SAVEALL')}>
              <Button type="submit" variant="contained" color="success" fullWidth>
                SAVE ALL
              </Button>
            </form>
          </Box> */}
        </Grid>

        {/* Grupo Derecho */}
        <Grid item xs={12} md={4}>
          <Typography variant="h6">Device Actions</Typography>

          
          

          {[
        { label: 'RELE 1', state: Toggled1, onChange: () => handleToggle(3, Toggled1, setToggled1) },
        { label: 'RELE 2', state: Toggled2, onChange: () => handleToggle(4, Toggled2, setToggled2) },
      ].map(({ label, state, onChange }, idx) => (
        <Box mt={3} key={idx}>
          <Typography>{label}</Typography>
          <ToggleButton state={state} onChange={onChange} />
        </Box>
      ))}

    <Box mt={3}>
      <TextField
        label="URL"
        variant="outlined"
        fullWidth
        margin="normal"
        value={url} // Vincula el estado al campo de entrada
        onChange={(e) => setUrl(e.target.value)} // Actualiza el estado al escribir
      />
      <form onSubmit={handleSubmiturl}>
        <Button type="submit" variant="contained" color="secondary" fullWidth>
          Update this
        </Button>
      </form>
    </Box>
    <Box mt={3}>
            <Typography>Versión Compilación: {currentID["Compilation"] || ''}</Typography>
          </Box>
          
        </Grid>
      </Grid>
    </Box>
  );
}

export default DevicesBendita;
