import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import { Grow   } from '@mui/material';
import { useInView } from 'react-intersection-observer';

const TitleSection: React.FC = () => {
  const { ref, inView } = useInView({
    triggerOnce: false, // Solo activa la animación una vez
    threshold: 0.2, // 20% del elemento visible para activar
});
  return (
    <Container
      sx={{
        textAlign: 'center',
        paddingY: 5,
        margin: "0 auto",
        maxWidth: {
          xs: '100%',   // Pantallas extra pequeñas
          sm: '100%',   // Pantallas pequeñas
          md: '100%',   // Pantallas medianas
          lg: '100%',   // Pantallas grandes
          xl: '2000px', // Pantallas extra grandes
        },
      }}
      ref={ref}
    >
      <Typography
        variant="h1"
        component="h1"
        sx={{
          fontWeight: 'bold',
          display: 'inline-flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          fontSize: { xs: '3rem', sm: '5rem', md: '6rem' }, // Tamaños responsivos
          gap: 0.5,
        }}
      >
        {/* Imagen en el título */}
        <Grow  in={inView} timeout={1000}>
        <Box
          component="img"
          src="/images/title.png"
          alt="Imagen Tinaco"
          sx={{
            width: "90%",        // Tamaño responsivo
            height: "auto",
            objectFit: 'contain',
            display: 'inline-block',
            verticalAlign: 'middle',
            margin: '0 auto',
          }}
        />
        </Grow >
      </Typography>

      {/* Texto descriptivo */}
      <Typography variant="h5">
        ¿Llega el agua y tú no estás en casa?
      </Typography>
    </Container>
  );
};

export default TitleSection;
