import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import 'animate.css';  // Asegúrate de que esta importación esté disponible
import { Fade  } from '@mui/material';
import { useInView } from 'react-intersection-observer';

const InfoSection: React.FC = () => {
        const { ref, inView } = useInView({
          triggerOnce: false, // Solo activa la animación una vez
          threshold: 0.2, // 20% del elemento visible para activar
      });

  return (
    <Container
      sx={{
        maxWidth: {
          xs: '100%',   // Pantallas extra pequeñas
          sm: '100%',   // Pantallas pequeñas
          md: '100%',   // Pantallas medianas
          lg: '100%',   // Pantallas grandes
          xl: '2000px', // Pantallas extra grandes
        },
      }}
      ref={ref}

    >
      <Grid container spacing={4} alignItems="center">
        {/* Imagen con animación */}
        <Fade in={inView} timeout={1000}>

        <Grid item xs={12} md={6} sx={{ textAlign: 'center' }}>
          <Box
            component="img"
            src="/images/back2.png"
            alt="Imagen Tinaco"
            sx={{
              width: 'auto',
              height: { xs: 300, sm: 400, md: 500 },  // Tamaño responsivo
              borderRadius: 3,
              objectFit: 'contain',
            }}
            className="animate__animated animate__slideInLeft "  // Animación con animate.css
          />
        </Grid>
        </Fade>


        {/* Texto de descripción */}
        <Grid item xs={12} md={6}>
          <Typography variant="h4" align="justify" gutterBottom  sx={{
                                        fontSize: {
                                            xs: '1.5rem',  // Extra pequeñas
                                            sm: '2rem',  // Pequeñas
                                            md: '2rem',  // Medianas
                                            lg: '3rem',  // Grandes
                                            xl: '4rem',  // Extra grandes
                                        },
                                        }}>
            Monitorea y administra tinacos, aljibes y mucho más, desde internet en tiempo real.
          </Typography>


          
        </Grid>
      </Grid>
    </Container>
  );
};

export default InfoSection;
