import React, { FC, useState } from 'react';
import { Button, TextField } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

interface UpdateSettingFormProps {
  currentResponse: string;
  handlePublish: (topic: string, message: string) => void;
  currentDevice: string;
  controlState: boolean;
  selectedItem: string;
}

const UpdateSettingForm: FC<UpdateSettingFormProps> = ({
  currentResponse,
  handlePublish,
  currentDevice,
  controlState,
  selectedItem,
}) => {
    const [url, setUrl] = useState('https://iotayudo.com/download/update.bin');

    const handleUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setUrl(event.target.value);
    };

    const handleSubmit = () => {
      if (!url) {
        alert("La URL no puede estar vacía."); // Validación simple
        return;
      }

    // Crear el JSON con la clave "updateFirmFrom" y la URL
    const jsonData = { updateFirmFrom: url };
    const jsonString = JSON.stringify(jsonData);

    // Publicar en el tópico MQTT correspondiente
    handlePublish(`REQUEST/${currentDevice}`, jsonString);
  };

  return (
    <div style={{ margin: '16px' }}>
      <TextField
        label="URL"
        variant="outlined"
        fullWidth
        value={url}
        onChange={handleUrlChange}
        margin="normal"
      />
      <Button
        startIcon={<SaveIcon />}
        variant="contained"
        color="primary"
        onClick={handleSubmit}
      >
        Enviar
      </Button>
    </div>
  );
};

export default UpdateSettingForm;
