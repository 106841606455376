import React, { useState, useEffect } from 'react';
// import { useLocation } from 'react-router-dom';
import { Box, Toolbar } from '@mui/material';
import { PROJECT_NAME } from './api/env';
import LayoutDrawer from './LayoutDrawer';
import LayoutAppBar from './LayoutAppBar';
// import { LayoutContext } from './context';
import PeriphericsStatusForm from './PeriphericsStatusForm';
import UpdateSettingForm from './updateSettingForm';
import { VictoryChart, VictoryLine, VictoryAxis, VictoryTooltip, VictoryLabel, VictoryContainer } from 'victory';
import TabChart from './graph/TabChart';
import ChatGPTExample from './ChatGPTExample';

export const DRAWER_WIDTH = 280;

const Layout = ({ handlePublish, 
  selectedItem,
  setSelectedItem,dataPeer,setDataPeer,eventCheckselectedItem,ctdsensor,

                currentResponse,
                setCurrentResponse,
                connected,blinkState,blinkStateMq,
                currentDevice,
                setCurrentDevice,imageSrc,fade,controlState, setControlState,waiting,setWaiting,qrcode}) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [title, setTitle] = useState(PROJECT_NAME);
//   const { pathname } = useLocation();

  const handleDrawerToggle = () => {
    // alert("setMobileOpen")
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => setMobileOpen(false), ["pathname"]);





  const renderComponent = () => {


    if(selectedItem && selectedItem.includes('/graph')){
      const match = selectedItem.match(/\/graph(\d+)/);
      if (match) {
        const index = parseInt(match[1], 10);
        return (
          <TabChart 
            sensor={index}
            dataPeer={dataPeer}
            setDataPeer={setDataPeer}
            eventCheckselectedItem={eventCheckselectedItem}
            currentResponse={currentResponse}
            setCurrentResponse={setCurrentResponse}
            currentDevice={currentDevice}
            handlePublish={handlePublish}
            waiting={waiting}
            setWaiting={setWaiting}
          />
        );
      }
      return;
    }
 




    switch (selectedItem) {
    case '/perif':
      return (          
      <>
      {/* <Toolbar /> */}
      {/* {children} */}
      <PeriphericsStatusForm 
          currentResponse={currentResponse} 
          handlePublish={handlePublish}
          currentDevice={currentDevice}
          controlState={controlState}
          selectedItem={selectedItem}
          /> 
      </>
      
        );
    case '/control':
      return (
        <>
        <ChatGPTExample              
        />  </>
      );
    case '/system':
        return (
          // <Layout/>
          <>
          <UpdateSettingForm 
          currentResponse={currentResponse} 
          handlePublish={handlePublish}
          currentDevice={currentDevice}
          controlState={controlState}
          selectedItem={selectedItem}
          /> 
          </>

        );
    // Agrega más casos según sea necesario para otros componentes
    default:
      return null;
  }
}
  



  return (
    // <LayoutContext.Provider value={{ title, setTitle }}>
    //   <LayoutAppBar title={title} onToggleDrawer={handleDrawerToggle} />
    <>
         <LayoutAppBar title={title} onToggleDrawer={handleDrawerToggle} connected={connected} blinkState={blinkState} blinkStateMq={blinkStateMq}/>

      <LayoutDrawer mobileOpen={mobileOpen} 
            onClose={handleDrawerToggle} 
            ctdsensor={ctdsensor}
            setSelectedItem={setSelectedItem} 
            selectedItem={selectedItem}

            setCurrentResponse={setCurrentResponse}
            setCurrentDevice={setCurrentDevice}
            imageSrc={imageSrc}
            fade={fade}
            qrcode={qrcode} 
      />
      <Box
        component="main"
        
        sx={{ marginLeft: { md: `${DRAWER_WIDTH}px`,marginTop: "5%",
        height: "100%" } }}
      >


{renderComponent()}

      </Box>
    {/* // </LayoutContext.Provider> */}
    
    </>
  );
};

export default Layout;
