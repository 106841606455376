import React from 'react';
import { Container, Grid, Card, CardContent, CardMedia, Typography, Button } from '@mui/material';

const VideoSection: React.FC = () => {
  const videos = [
    {
      id: 'eUq7gAVV5TA',
      title: 'Video 1',
    }
    
  ];

  return (
    <Container sx={{ paddingY: 5 }}>
      <Typography variant="h3" align="center" gutterBottom>
        Sección de Videos
      </Typography>
      <Grid container spacing={4}>
        {videos.map((video) => (
          <Grid item xs={12} md={12} key={video.id}>
            <Card>
              <CardMedia
                component="iframe"
                src={`https://www.youtube.com/embed/${video.id}?autoplay=1&loop=1&playlist=${video.id}`}
                title={video.title}
                sx={{ height:"300px", margin:"0 auto" }} // Ajusta la altura según sea necesario
                frameBorder="0"
                allowFullScreen
              />
              {/* <CardContent>
                <Typography variant="h6">{video.title}</Typography>
                <Button
                  variant="contained"
                  color="primary"
                  href={`https://www.youtube.com/watch?v=${video.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Ver en YouTube
                </Button>
              </CardContent> */}
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default VideoSection;
